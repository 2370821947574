<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!--------------------- COLUNA DA IMAGEM  ---------------------->
      <b-col
        lg="8"
        class="colunaoitocamadas d-none d-lg-flex align-items-center"
        style="padding-right: 0; padding-left: 0;
        background-image: url('https://arquivos.devnx.com.br/goomove/possebon/uploads/mpe.jpg');
         background-repeat: round;"
      />

      <!--------------------- COLUNA DAS INFORMAÇÕES  ---------------------->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Bem vindo! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Faça login com a sua conta
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                class="label-pesa"
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="nome@pesacat.com"
                    autofocus
                  />
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="label-pesa">Senha</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="*******"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group class="d-inline">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  class="d-inline"
                  name="checkbox-1"
                >
                  Lembrar de mim
                </b-form-checkbox>
              </b-form-group>
              <b-form-group class="d-inline float-right">
                <div class="senha justify-content-between">
                  <b-link :to="{name:'recuperar-senha'}">
                    <small class="esqueceu">Esqueceu a senha?</small>
                  </b-link>
                </div>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                block
                :disabled="invalid"
                class="btn-login"
              >
                Acessar
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span class="novo">Novo em nossa plataforma Promocional? </span>
            <b-link :to="{name:'auth-cadastro'}">
              <span class="conta">Crie a sua conta aqui</span>
            </b-link>
          </b-card-text>

          <!--------------------- LOGO PESA  ---------------------->
          <b-row class="mt-5">
            <b-col md="3" />
            <b-col md="6">
              <b-img
                class="img-logo-pesa"
                src="https://arquivos.devnx.com.br/goomove/possebon/uploads/logo_branco.png"
              />
            </b-col>
            <b-col md="3" />
          </b-row>
        </b-col>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$http.post('auth/login', { email: this.userEmail, password: this.password })
            .then(response => {
              if (response?.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Erro ao entrar',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'Login ou senha invalidos',
                  },
                })
                return
              }
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.push(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Olá ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Seja bem vindo!. Você não possui nenhum aviso',
                    },
                  })
                })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.btn-login{
  background-color: #0d6e8a !important;
  border-color: #0d6c88 !important;
}
.corpo.card{
  background-color: #000000b0;
}
.titulo{
  color: #f0f0f0;
}
.texto{
  color: #ffffff;
}
.d-block{
  color: #ffffff;
}
.senha label{
  color: #ffffff;
}
.esqueceu{
  color: #f0f0f0;
}
.lembre label{
  color: #f0f0f0;
}
.botao{
  border-color: #fafefe !important;
  background-color: #fec91a !important;
  color: #000 !important;
}
.crie{
  color: #ffffff;
}
.criar-conta{
  color: #fec91a;
}

.form-control:focus {
  border-color: #9b800d !important;
}
.btn-primary:active {
  background-color: #bf9301 !important;
  border-color: #1e191a !important;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #b18801;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #bb9822;
}

/********** Titulo ***********/
h2.card-title.mb-1.font-weight-bold {
  color: #FFF;
}

/********** cor de fundo ***********/
[dir] .auth-wrapper .auth-bg {
  background-color: #00576d !important;
}

/********** cor da frase faça login ***********/
p.card-text.mb-2 {
  color: aliceblue;
}

/********** cor das label ***********/
p.card-text.mb-2 {
  color: aliceblue;
}
label {
  color: aliceblue;
  font-size: 0.857rem;
}
/********** cor da fonte lembrar de mim ***********/
label.custom-control-label {
  color: #FFF;
}

/********** cor da fonte esqueceu a senha ***********/
small {
  color: #FFF;
}

/********** cor da fonte Novo na plataforma ***********/
span.novo {
  color: aliceblue;
}

/********** cor da fonte Criar um conta ***********/
span.conta {
  color: #FFF;
}
img.img-logo-pesa {
  width: 100%;
}
</style>
